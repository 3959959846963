import React from "react";
import {
  FormControl, Button, Grid, TextField, InputAdornment, IconButton, FormControlLabel,
  Switch, FormGroup
} from "@mui/material";
import { useSelector } from "react-redux";
import ClearIcon from '@mui/icons-material/Clear';

import ExpedientModal from "./ExpedientModal";
import { PERMISSIONS } from "../../constants";

const BindExpedient = ({
  flow, expedient, setExpedient, copyInExpedient, setCopyInExpedient, disabled
}) => {
  const user = useSelector((state) => state.user.value);

  return (
    <>
      <ExpedientModal
        open={expedient === null}
        onClose={() => setExpedient(undefined)}
        onSelect={(expedient) => setExpedient(expedient)}
      />
      <FormControl fullWidth sx={{ mb: 1 }}>
        {!expedient
          && <Button
            variant="contained"
            onClick={() => setExpedient(null)}
          >
            Relacionar Expediente
          </Button>
        }
        {expedient
          && <Grid container>
            <Grid item xs={9}>
              <TextField
                label="Relacionar expediente"
                defaultValue={`${expedient.description} (${expedient.expedientId})`}
                fullWidth
                disabled={disabled}
                InputProps={{
                  readOnly: true,
                  endAdornment: <InputAdornment position="end">
                    {user.permissions.includes(PERMISSIONS.PROCESS.SAVE_AS_ANY_EXPEDIENT)
                      && <IconButton onClick={() => setExpedient(undefined)}>
                        <ClearIcon />
                      </IconButton>
                    }
                  </InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={1} sx={{ px: 1 }}>
              <FormGroup>
                <FormControlLabel
                  disabled={disabled || flow.saveAs === 'expedient'}
                  control={<Switch
                    checked={copyInExpedient || flow.saveAs === 'expedient'}
                    onChange={(evt) => setCopyInExpedient(evt.target.checked)}
                  />}
                  label="Copiar en expediente"
                />
              </FormGroup>
            </Grid>
          </Grid>
        }
      </FormControl>
    </>
  );
};

export default BindExpedient;
