import React, { useRef, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import html2canvas from 'html2canvas';
import { PDFDocument } from 'pdf-lib';

const FiledInsertQR = ({
  fileUrl, labelQRRef, handleFileUpload, loading, setLoading, onFileLoaded
}) => {
  const divRef = useRef(null);
  const [editing, setEditing] = useState(false);
  const [position, setPosition] = useState({ top: 10, left: 10 });

  const handleMouseMove = (evt) => {
    if (!editing) return;
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      const left = evt.clientX - rect.left;
      const top = evt.clientY - rect.top;
      setPosition({ left, top });
    }
  };

  const handleAddQR = async () => {
    setLoading(true);

    let url = fileUrl.split('/');
    url = `https://${url[3]}.${url[2]}/${url[4]}?v=${new Date().getTime()}`;
    const pdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const { height } = firstPage.getSize();
    
    html2canvas(labelQRRef.current).then(async (canvas) => {
      let img = canvas.toDataURL('image/png');
      img = img.split(',')[1];
      img = Uint8Array.from(atob(img), (char) => char.charCodeAt(0));
      img = await pdfDoc.embedPng(img);
      firstPage.drawImage(img, {
        x: position.left,
        y: height - 60 - position.top,
        width: 225,
        height: 55
      });
      const modifiedPdfBytes = await pdfDoc.save();
      const file = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      await handleFileUpload({ target: { files: [file] }});
      setLoading(false);
      if (onFileLoaded) onFileLoaded();
    });
  };

  return (
    <div
      ref={divRef}
      style={{
        backgroundColor: 'rgba(255,0,0,0.3)',
        position: 'absolute',
        height: 'calc(100% - 60px)',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '60px',
        width: '612px'
      }}
      onMouseMove={handleMouseMove}
    >
      <Button
        variant='contained'
        sx={{
          position: 'absolute',
          bottom: 10,
          right: 10
        }}
        disabled={loading}
        onClick={handleAddQR}
      >
        {loading ? <CircularProgress size={24} /> : "Aplicar"}
      </Button>
      <div
        onClick={() => setEditing(!editing)}
        style={{
          backgroundColor: editing ? 'white': 'transparent',
          border: 'thin solid blue',
          width: 225,
          height: 55,
          position: 'relative',
          ...position
        }} />
    </div>
  );
};

export default FiledInsertQR;
