import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import PrintIcon from "@mui/icons-material/Print";

import {
  doneFiled,
  generateReferenceFiled,
  getCorrespondences,
  getFiled,
  getFiledOnSnapshot,
  updateFiled,
} from "../../services/backend/CorrespondenceService";
import { deleteFile, uploadFile } from "../../services/backend/FileSystemService";
import { setCorrespondence } from "../../redux/slices/correspondenceSlice";
import { debugLog } from "../../functions";

import LabelQR from "../filed/LabelQR";
import MySnackbar from "../generic/MySnackbar";
import SelectUser from "../generic/SelectUser";
import MyBackdrop from "../generic/MyBackdrop";
import { PERMISSIONS } from "../../constants";
import AnnexesUploader from "../generic/AnnexesUploader";
import BindExpedient from "../expedient/BindExpedient";
import FiledInsertQR from "./FiledInsertQR";

export default function FiledForm() {
  const dispatch = useDispatch();
  const labelQRRef = useRef();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const correspondences = useSelector((state) => state.correspondences.value);
  const { reference } = useParams();
  const [referenceCode, setReferenceCode] = useState();
  const [referenceId, setReferenceId] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [filedType, setFiledType] = useState("");
  const [folios, setFolios] = useState(0);
  const [subject, setSubject] = useState();
  const [recipients, setRecipients] = useState([]);
  const [senders, setSenders] = useState([]);
  const [externalFiled, setExternalFiled] = useState();
  const [externalDate, setExternalDate] = useState();
  const [attach, setAttach] = useState();
  const [documentText, setDocumentText] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [status, setStatus] = useState("OPEN");
  const [loading, setLoading] = useState(false);
  const [infoSnack, setInfoSnack] = useState({ open: false });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [annexes, setAnnexes] = useState([]);
  const [expedient, setExpedient] = useState(undefined);
  const [copyInExpedient, setCopyInExpedient] = useState(false);
  const [tagEditing, setTagEditing] = useState(false);

  const version = useMemo(() => new Date().getTime(), [tagEditing]);
  const canFinish = useMemo(
    () => subject && recipients.length && senders.length && fileUrl,
    [subject, recipients, senders, fileUrl]
  );

  const updateCorrespondences = async () => {
    const responseJSON = await getCorrespondences(user.token);
    dispatch(setCorrespondence(responseJSON));
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);

    const filed = {
      filedId: referenceCode,
      referenceId,
      subject,
      filedType,
      folios,
      recipients,
      senders,
      externalFiled,
      externalDate,
      attach,
      fileUrl,
      createdAt,
      annexes,
      expedient: expedient ? { ...expedient, copyInExpedient } : null
    };

    if (document.activeElement.dataset.finish) {
      await doneFiled(user.token, filed);
      navigate("/filedlist");
    } else {
      await updateFiled(user.token, filed);
      setInfoSnack({ open: true, message: "Radicado guardado con éxito" });
    }

    setLoading(false);
  };

  const handleFiledGenerate = async () => {
    setLoading(true);
    const responseJSON = await generateReferenceFiled(user.token, {
      correspondenceId: filedType,
      filedType,
      folios,
    });

    setReferenceCode(responseJSON.referenceCode);
    setReferenceId(responseJSON.referenceId);
    setCreatedAt(responseJSON.createdAt);
    setLoading(false);

    if (!reference)
      navigate(`/filed/${responseJSON.referenceCode}`, { replace: true });
  };

  const handleDeleteUpload = async () => {
    setFileUrl(null);
    setOpenBackdrop(true);
    const localFile = referenceCode + "/" + referenceCode + ".pdf";
    debugLog("localFile: ", localFile);
    const bucket = process.env.REACT_APP_STORAGE_BUCKET_FILED;
    try {
      const response = await deleteFile(user.token, bucket, localFile);
      debugLog("response: ", response);
    } catch (error) {
      debugLog("response: ", error);
    }
    setOpenBackdrop(false);
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    let localFile = event.target.files[0];
    if (localFile) {
      const correspondenceId = referenceCode;
      const metadata = {
        dato1: "dato1",
        dato2: "dato2",
        dato3: "dato3",
      };
      const bucket = process.env.REACT_APP_STORAGE_BUCKET_FILED;
      const token = user.token;
      const response = await uploadFile(
        token,
        localFile,
        correspondenceId,
        metadata,
        bucket,
        correspondenceId + ".pdf",
        true
      );

      setFileUrl(response.downloadUrl);
    }
    setLoading(false);
  };

  const handleFieldCancel = async () => {
    setLoading(true);

    const filed = {
      filedId: referenceCode,
      referenceId,
      status: "CANCELLED",
    };

    await updateFiled(user.token, filed);
    setLoading(false);
    navigate("/filedlist");
  };

  const onPrintLabel = () => {
    // Crear un elemento de estilo y añadir estilos para la impresión.
    const printStyle = document.createElement("style");
    printStyle.type = "text/css";
    printStyle.innerHTML = `
    @media print {
      @page {
        size: 20cm 4cm; /* Tamaño de la etiqueta a imprimir */
        margin: 0; /* Eliminar márgenes de la página */
      }
      body, html {
        margin: 0;
        padding: 0;
        overflow: hidden; /* Evitar el desbordamiento de la página */
        width: 20cm;
        height: 4cm;
      }
      body * {
        visibility: hidden; /* Ocultar todo el contenido */
        max-height: none !important;
      }
      .printable, .printable * {
        visibility: visible; /* Solo mostrar el contenido del elemento a imprimir */
        max-width: 20cm; /* Ajustar el ancho del contenido a imprimir */
        max-height: 4cm; /* Ajustar la altura del contenido a imprimir */
        overflow: hidden; /* Asegurarse de que no haya desbordamiento */
      }
      .printable {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  `;
    // Añadir el elemento de estilo al documento.
    document.head.appendChild(printStyle);

    // Asegurarse de que el elemento a imprimir tenga la clase 'printable'.
    labelQRRef.current.classList.add("printable");

    window.onafterprint = function () {
      // Eliminar los estilos añadidos después de imprimir.
      document.head.removeChild(printStyle);
      // Restaurar la visibilidad y eliminar la clase 'printable'.
      labelQRRef.current.classList.remove("printable");
      window.onafterprint = null;
    };

    // Llamar a la función de impresión del navegador.
    window.print();
  };

  useEffect(() => {
    if ((!reference && !user.permissions.includes(PERMISSIONS.FILED.CREATE))
      || (reference && !user.permissions.includes(PERMISSIONS.FILED.UPDATE))) {
      return navigate("/filedlist");
    }
    
    if (reference && !referenceId) {
      (async () => {
        let responseJSON = await getFiled(user.token, reference);
        if (responseJSON.result === false) navigate("/filedlist");
        setReferenceCode(responseJSON.filedId);
        setReferenceId(responseJSON.referenceId);
        setCreatedAt(responseJSON.createdAt);
        setFiledType(responseJSON.filedType);
        setFolios(responseJSON.folios);
        setSubject(responseJSON.subject);
        setRecipients(responseJSON.recipients || []);
        setSenders(responseJSON.senders || []);
        setExternalFiled(responseJSON.externalFiled);
        setExternalDate(responseJSON.externalDate);
        setAttach(responseJSON.attach);
        setFileUrl(responseJSON.fileUrl);
        setDocumentText(responseJSON.documentText);
        setStatus(responseJSON.status);
        setAnnexes(responseJSON.annexes ? responseJSON.annexes : []);
        setExpedient(responseJSON.expedient);
        setCopyInExpedient(responseJSON.expedient?.copyInExpedient);
      })();
    }
    if (!correspondences) {
      updateCorrespondences();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (referenceCode && !user.permissions.includes(PERMISSIONS.FILED.UPDATE)) {
      return navigate("/filedlist");
    }

    if (referenceCode && !documentText) {
      const unsubscribe = getFiledOnSnapshot("filed", referenceCode, (doc) => {
        if (doc.documentText) setDocumentText(doc.documentText);
      });

      return () => unsubscribe();
    }
  }, [referenceCode]); // eslint-disable-line

  return (
    <>
      <Container maxWidth={false}>
        <Grid container sx={{ mb: 1 }}>
          <Grid item xs={6}>
            <Box component="form" onSubmit={handleSubmit} sx={{ m: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <FormControl fullWidth sx={{ marginY: 1 }}>
                    <InputLabel id="filed-type-label">
                      Tipo de Radicado
                    </InputLabel>
                    {correspondences
                      && <Select
                        labelId="filed-type-label"
                        label="Tipo de Radicado"
                        required
                        value={filedType}
                        onChange={(evt) => setFiledType(evt.target.value)}
                        disabled={!!referenceCode}
                      >
                        {correspondences.filter((cor) => cor.resultType === 'filed').map((cor) => (
                          <MenuItem key={cor.correspondenceId} value={cor.correspondenceId}>
                            {cor.subserieName}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth sx={{ marginY: 1 }}>
                    <TextField
                      label="No de Folios"
                      required
                      value={folios}
                      type="number"
                      onChange={(evt) => setFolios(evt.target.value ?? null)}
                      disabled={!!referenceCode}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Box sx={{ display: referenceCode ? "block" : "none" }}>
                {(!reference || referenceId) && (
                  <>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <TextField
                        label="Asunto"
                        rows={3}
                        fullWidth
                        autoFocus
                        multiline
                        defaultValue={subject}
                        disabled={status === "DONE"}
                        onChange={(evt) => setSubject(evt.target.value)}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <SelectUser
                        multiple
                        userType={
                          ["internal", "received"].includes(filedType)
                            ? "users"
                            : "people"
                        }
                        noedit={["internal", "received"].includes(filedType)}
                        value={recipients}
                        setValue={setRecipients}
                        disabled={status === "DONE"}
                        placeholder="Destinatario"
                        onChange={(__, value) => setRecipients(value)}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <SelectUser
                        multiple={true}
                        userType={
                          ["internal", "external"].includes(filedType)
                            ? "users"
                            : "people"
                        }
                        noedit={["internal", "external"].includes(filedType)}
                        value={senders}
                        setValue={setSenders}
                        disabled={status === "DONE"}
                        placeholder="Remitente"
                        onChange={(__, value) => setSenders(value)}
                      />
                    </FormControl>

                    <Grid container spacing={2} sx={{ mb: 1 }}>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            label="Radicado externo"
                            fullWidth
                            defaultValue={externalFiled}
                            disabled={status === "DONE"}
                            onChange={(evt) =>
                              setExternalFiled(evt.target.value)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <DatePicker
                            label="Fecha externa"
                            defaultValue={
                              (externalDate && moment(externalDate)) ||
                              undefined
                            }
                            onChange={(momt) => setExternalDate(momt)}
                            disabled={status === "DONE"}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <AnnexesUploader
                      referenceCode={referenceCode}
                      annexes={annexes}
                      setAnnexes={setAnnexes}
                      attach={attach}
                      setAttach={setAttach}
                      disabled={status === "DONE"}
                    />
                    
                    <BindExpedient
                      expedient={expedient}
                      setExpedient={setExpedient}
                      copyInExpedient={copyInExpedient}
                      setCopyInExpedient={setCopyInExpedient}
                    />

                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <TextField
                        label={!documentText && "Contenido del documento"}
                        defaultValue={documentText}
                        rows={3}
                        fullWidth
                        multiline
                        disabled
                      />
                    </FormControl>
                  </>
                )}
              </Box>

              <Box sx={{ textAlign: "center" }}>
                <LabelQR
                  ref={labelQRRef}
                  filed={{
                    referenceId,
                    referenceCode,
                    folios,
                    createdAt,
                    type: filedType
                  }}
                />
                {referenceCode && (
                  <Tooltip title="Imprimir" placement="top">
                    <IconButton onClick={onPrintLabel} color="primary">
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>

              <Box
                justifyContent="center"
                sx={{ display: referenceCode ? "none" : "flex", mt: 2 }}
              >
                <Button
                  disabled={!filedType || !folios || loading}
                  variant="contained"
                  onClick={handleFiledGenerate}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Generar etiqueta"
                  )}
                </Button>
              </Box>

              <Grid
                container
                spacing={2}
                sx={{ mb: 2, mt: 1 }}
                textAlign="center"
                display={referenceCode ? "flex" : "none"}
              >
                {status === "OPEN" && (
                  <>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        disabled={loading || !user.permissions.includes(PERMISSIONS.FILED.CANCEL)}
                        onClick={handleFieldCancel}
                      >
                        {loading ? <CircularProgress size={24} /> : "Anular"}
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        type="submit"
                        data-finish="true"
                        disabled={!canFinish || loading}
                      >
                        {canFinish && loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Finalizar"
                        )}
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={24} /> : "Guardar"}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={6} sx={{ minHeight: 600, textAlign: "center", position: "relative" }}>
            {fileUrl ? (
              <>
                {tagEditing
                  && <FiledInsertQR
                    fileUrl={fileUrl}
                    labelQRRef={labelQRRef}
                    handleFileUpload={handleFileUpload}
                    loading={loading}
                    setLoading={setLoading}
                    onFileLoaded={() => setTagEditing(false)}
                  />
                }
                <iframe
                  title="File PDF"
                  src={`${fileUrl}?v=${version}`}
                  width="100%"
                  height="100%"
                  seamless="seamless"
                  scrolling="no"
                  frameBorder="1"
                  allowFullScreen={true}
                />
                {status === "OPEN" && (
                  <>
                    <Button
                      variant="outlined"
                      sx={{ mt: 2 }}
                      size="small"
                      onClick={handleDeleteUpload}
                    >
                      Actualizar archivo
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ mt: 2, ml: 2 }}
                      size="small"
                      onClick={() => setTagEditing(!tagEditing)}
                    >
                      {tagEditing ? 'Cancelar etiqueta' : 'Agregar etiqueta'}
                    </Button>
                  </>
                )}
              </>
            ) : (
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  backgroundColor: "lightgray",
                  border: "2px dashed gray",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <label htmlFor="upload-image">
                  <Button
                    variant="contained"
                    component="span"
                    disabled={loading || !referenceCode}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Cargar archivo"
                    )}
                  </Button>
                  {referenceCode && (
                    <input
                      id="upload-image"
                      hidden
                      accept="application/pdf"
                      type="file"
                      onChange={handleFileUpload}
                    />
                  )}
                </label>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
      <MySnackbar
        info={infoSnack}
        handleClose={() => setInfoSnack({ open: false })}
      />
      <MyBackdrop open={openBackdrop} />
    </>
  );
}
