import { useState } from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import ContractNewsForm from "./ContractNewsForm";

const ContractNews = ({ contract }) => {
  const [showNewsModal, setShowNewsModal] = useState(false);

  const handleAdd = () => {
    setShowNewsModal(true);
  };

  return (
    <>
      <Modal
        open={showNewsModal}
        onClose={() =>setShowNewsModal(false)}
      >
        <Box>
          <ContractNewsForm contract={contract} onSubmit={() => setShowNewsModal(false)} />
        </Box>
      </Modal>

      <Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ color: "gray", flex: 1 }}>Novedades</Typography>
          <IconButton onClick={handleAdd}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Box>
        {contract.news?.map((news) => (
          <Box>{news.type}</Box>
        ))}
      </Box>
    </>
  );
};

export default ContractNews;
