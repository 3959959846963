import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment/moment";

import { getTask, updateTask } from "../../services/backend/TaskService";
import { doneActionFlow, handlePdfProcess, rejectActionFlow, updateFlow } from "../../services/backend/FlowService";
import { debugLog } from "../../functions";
import MySnackbar from "../generic/MySnackbar";
import Flow from "../flow/Flow";
import RegisterSignature from "./RegisterSignature";
import Editor from "../flow/Editor";
import RichEditor from '../generic/rich-editor';
import DialogConfirmation from "../generic/DialogConfirmation";
import FormTagsModal from "../generic/FormTagsModal";
import DocumentPreviewModal from "../generic/DocumentPreviewModal";
import MyBackdrop from "../generic/MyBackdrop";

export default function Task({ taskIdNew }) {
  const navigate = useNavigate();
  const { taskId } = useParams();
  const user = useSelector((state) => state.user.value);
  const [task, setTask] = useState(null);
  const [infoSnack, setInfoSnack] = useState({ open: false });
  const [flow, parentSetFlow] = useState();
  const [validatedEditor, setValidatedEditor] = useState(false);
  const [openRejectAction, setOpenRejectAction] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState({ open: false });
  const [confirmInfoSign, setConfirmInfoSign] = useState({ open: false });
  const [mustFinish, setMustFinish] = useState();
  const [editorRef, setEditorRef] = useState();
  const [tagValues, setTagValues] = useState();
  const [openFormTagsModal, setOpenFormTagsModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [finishing, setFinishing] = useState(false);

  const dataSource = useMemo(() => {
    if (!flow) return null;

    const keys = Object.keys(flow.template.tags);
    let data = {};
    let tags = [];
    let mustOpenFormTagsModal = false;

    if (flow.document) {
      keys.forEach((key) => {
        if (!['users', 'people'].includes(flow.template.tags[key].type)) {
          if (flow.document.tags && flow.document.tags[key]) {
            data[key] = flow.document.tags[key];
          } else {
            data[key] = `<<${key}>>`;
            mustOpenFormTagsModal = true;
          }
          tags.push(key);
        } else {
          const anchs = { ...flow.template.tags[key].anchors };
          const anchors = {};
          Object.keys(anchs).forEach((k) => {
            if ((flow.document.tags && flow.document.tags[k])) {
              anchors[k] = flow.document.tags[k];
            } else {
              anchors[k] = `<<${k}>>`;
              mustOpenFormTagsModal = true;
            }
            tags.push(k);
          });
          data = { ...data, ...anchors };
        }
      });
    }


    if (!tagValues) {
      setOpenFormTagsModal(mustOpenFormTagsModal);
      setTagValues(flow.document?.tags);
    }

    return { data, tags };
  }, [flow]); // eslint-disable-line

  const handleCloseConfirmDialog = async (result) => {
    debugLog("handleCloseConfirmDialog: ", result);
    setConfirmInfo({ open: false });
    setOpenRejectAction(false);
    if (result) {
      const body = {
        taskId: task.taskId,
        action: task.action,
        stepId: "done",
        status: task.rejectType === "task" ? "reject" : "cancelled",
        doneAt: new Date(),
        rejectedAt: new Date(),
        signed: false,
        rejectComment: task.rejectComment,
        rejectType: task.rejectType,
      };
      
      const responseJSON = await updateTask(body, user);
      
      if (responseJSON.result) {
        setTask({
          ...task,
          stepId: "done",
          status: task.rejectType === "task" ? "reject" : "cancelled",
          doneAt: new Date(),
          rejectedAt: new Date(),
          signed: false,
        });
      }
      
      const bodyFlow = {
        taskId: task.taskId,
        flowId: task.flowId,
        action: task.action,
        rejectComment: task.rejectComment,
        rejectType: task.rejectType,
      };

      const flowJSON = await rejectActionFlow(bodyFlow, user);
      if (flowJSON.result) {
        setInfoSnack({ open: true, message: "Tarea Rechazada" });
      }
    } else {
      setTask({
        ...task,
        rejectComment: "",
      });
    }
  };

  const handleUpdateTask = (e) => {
    setTask({
      ...task,
      [e.target.name]: e.target.value,
    });
  };

  const handleSigned = async (signed) => {    
    if (validatedEditor) {
      setInfoSnack({
        open: true,
        message: validatedEditor,
        severity: "warning",
      });
      return;
    }
    if (flow.saveAs === 'expedient' && !flow.expedient) {
      setInfoSnack({
        open: true,
        message: "Debe relacionar el expediente donde será guardado el documento",
        severity: "warning",
      });
      return;
    }
    debugLog("isSign ", signed);
    const action = { ...task.action };
    action.signed = signed;
    if (signed) {
      action.signedAt = new Date();
      action.status = "done";
    } else {
      action.signedAt = null;
      action.status = "pending";
    }
    setTask({
      ...task,
      action,
    });
  };

  const handleCloseConfirmDialogSign = async (result) => {
    setConfirmInfoSign({ open: false });

    if (result) {
      const body = {
        taskId: task.taskId,
        action: task.action,
        stepId: "done",
        status: "done",
        doneAt: new Date(),
        signedAt: new Date(),
        signed: true,
      };

      //update Task
      const responseJSON = await updateTask(body, user);
      if (responseJSON.result) {
        setTask({
          ...task,
          stepId: "done",
          status: "ok",
          doneAt: new Date(),
          signed: true,
        });
      }

      const bodyFlow = {
        taskId: task.taskId,
        flowId: task.flowId,
        action: task.action,
      };

      const flowJSON = await doneActionFlow(bodyFlow, user);

      if (flowJSON.result) {
        setInfoSnack({ open: true, message: "Tarea firmada correctamente" });
        if (task.action.isLast) setMustFinish(true);
      }
    }
  };

  const handleDoneTask = async () => {
    const title = "¿Confirma la finalización de esta tarea?";
    setConfirmInfoSign({
      open: true,
      title,
    });
  };

  const handleRejectTask = async () => {
    debugLog("handleRejectTask ", task);
    if (task.rejectComment) {
      const title =
        "Confirmar que quieres rechazar " +
        (task.rejectType === "task" ? "esta Tarea ?" : "el flujo completo ?");
      setConfirmInfo({
        open: true,
        title,
      });
    }
  };

  const handleGetTask = async (taskId) => {
    if (user) {
      const body = {
        filter: "BY_ID",
        value: taskId,
      };

      let responseJSON = await getTask(body, user);
      if (responseJSON[0]) {
        if (!responseJSON[0].rejectType) responseJSON[0].rejectType = "task";

        setTask(responseJSON[0]);
      }
    }
  };

  useEffect(() => {   
    if (taskId) {
      handleGetTask(taskId);
    } else if (taskIdNew) {
      handleGetTask(taskIdNew);
    }
  }, [taskId, taskIdNew]); // eslint-disable-line

  useEffect(() => {
    if (tagValues) {
      const flowBody = {
        flowId: flow.flowId,
        document: {
          ...flow.document,
          tags: tagValues
        },
      };
      updateFlow(flowBody, user);
    }
  }, [tagValues]); // eslint-disable-line

  useEffect(() => {
    if (mustFinish && flow.document?.file) {
      setFinishing(
        "Iniciando creación de PDF. Puedes consultarlo en un momento.."
      );
      handlePdfProcess(flow, user);
      setTimeout(() => {
        setFinishing(false);
        navigate("/filedlist");
      }, 2000);
    }
  }, [mustFinish]); // eslint-disable-line

  return (
    <Container maxWidth={false} style={{ padding: 3, marginTop: 10 }}>
      {task ? (
        task.taskType === "flow" ? (
          <Grid container>
            <Grid item xs={5}>
              <Flow
                flowIdNew={task.flowId}
                parentSetFlow={parentSetFlow}
                disabled={task.action.owners[0].userId !== user.userId || task.status === 'done'}
              />
              {user.roles.includes('ADMIN') || task.action.owners[0].userId === user.userId ? (
                task.stepId !== "done" ? (
                  openRejectAction ? (
                    <Box
                      sx={{
                        m: 1,
                        p: 1,
                        border: "1px dashed grey",
                        "&::-webkit-scrollbar": {
                          width: 3,
                        },
                        "&::-webkit-scrollbar-track": {
                          "-webkit-box-shadow":
                            "inset 0 0 6px rgba(0,0,0,0.00)",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "rgba(0,0,0,.1)",
                          outline: "1px solid slategrey",
                        },
                      }}
                    >
                      <FormControl>
                        <FormLabel id="rejectType">Tipo de Rechazo</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="rejectType"
                          name="rejectType"
                          value={task.rejectType ? task.rejectType : "task"}
                          onChange={handleUpdateTask}
                        >
                          {task.action.isFirst ? null : (
                            <FormControlLabel
                              value="task"
                              control={<Radio />}
                              label="Tarea"
                            />
                          )}
                          <FormControlLabel
                            value="flow"
                            control={<Radio />}
                            label="Flujo"
                          />
                        </RadioGroup>
                      </FormControl>
                      <Stack direction="row">
                        <TextField
                          id="rejectComment"
                          name="rejectComment"
                          label="Motivo de rechazo"
                          variant="outlined"
                          fullWidth
                          multiline
                          required
                          value={task.rejectComment}
                          error={!task.rejectComment}
                          helperText={
                            task.rejectComment ? "" : "Campo obligatorio"
                          }
                          rows={2}
                          onChange={handleUpdateTask}
                        />
                        <Stack direction="column">
                          <DoneIcon
                            color="primary"
                            disabled={!task.rejectComment}
                            onClick={() => handleRejectTask(true)}
                          />
                          <CloseIcon
                            color="primary"
                            onClick={() => {
                              setTask({ ...task, rejectComment: "" });
                              setOpenRejectAction(!openRejectAction);
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Box>
                  ) : (
                    <>
                      {task.description ? (
                        <TextField
                          id="description"
                          name="description"
                          label="Observación"
                          variant="outlined"
                          fullWidth
                          multiline
                          required
                          value={task.description}
                          disabled
                          rows={2}
                        />
                      ) : null}

                      <RegisterSignature
                        actionName={task.action.actionNamePP}
                        signed={task.action.signed}
                        setSigned={handleSigned}
                        edit={true}
                      />
                      <Stack direction="row" spacing={2} margin={2}>
                        <Button
                          variant="contained"
                          disabled={!task.action.signed}
                          onClick={handleDoneTask}
                        >
                          Finalizar
                        </Button>
                        <Button
                          onClick={(evt) => {
                            setTask({ ...task, rejectType: "task" });
                            setOpenRejectAction(true);
                          }}
                        >
                          Rechazar
                        </Button>
                      </Stack>
                    </>
                  )
                ) : task.status === "reject" || task.status === "cancelled" ? (
                  <Box
                    sx={{
                      m: 1,
                      p: 1,
                      border: "1px dashed grey",
                      "&::-webkit-scrollbar": {
                        width: 3,
                      },
                      "&::-webkit-scrollbar-track": {
                        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgba(0,0,0,.1)",
                        outline: "1px solid slategrey",
                      },
                    }}
                  >
                    <Stack direction="column" spacing={1}>
                      <TextField
                        id="rejectType"
                        name="rejectType"
                        label="Tipo de rechazo"
                        variant="outlined"
                        required
                        value={task.rejectType}
                        disabled
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        id="rejectedAt"
                        name="rejectedAt"
                        label="Fecha de rechazo"
                        variant="outlined"
                        required
                        value={moment(task.rejectedAt).format("LL, h:mm:ss a")}
                        disabled
                        fullWidth
                      />
                      <TextField
                        id="rejectComment"
                        name="rejectComment"
                        label="Motivo de rechazo"
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        value={task.rejectComment}
                        disabled
                        rows={2}
                      />
                    </Stack>
                  </Box>
                ) : (
                  <RegisterSignature
                    actionName={task.action.actionNamePP}
                    signed={task.action.signed}
                    setSigned={handleSigned}
                    edit={false}
                  />
                )
              ) : (
                <RegisterSignature
                  actionName={task.action.actionNamePP}
                  signed={task.action.signed}
                  setSigned={handleSigned}
                  edit={false}
                />
              )}
            </Grid>
            <Grid item xs={7}>
              {flow && (
                flow.document?.file ?
                  <RichEditor
                    readOnly={
                      task.stepId !== "init" ||
                      task.action.owners[0].userId !== user.userId
                    }
                    tags={dataSource.tags}
                    dataSource={dataSource.data}
                    file={flow.document.file}
                    setValidatedEditor={setValidatedEditor}
                    setEditorRef={setEditorRef}
                    itemEvents={{
                      openTagModalEvent: () => setOpenFormTagsModal(true),
                      openPreviewModalEvent: () => setOpenPreviewModal(true)
                    }}
                  />
                : <Editor
                  disabled={
                    task.stepId !== "init" ||
                    task.action.owners[0].userId !== user.userId
                  }
                  flow={flow}
                  setValidatedEditor={setValidatedEditor}
                  mustFinish={mustFinish}
                />
              )}
            </Grid>
          </Grid>
        ) : null
      ) : (
        <CircularProgress />
      )}
      <DialogConfirmation
        confirmInfo={confirmInfo}
        handleCloseDialog={handleCloseConfirmDialog}
      />
      <DialogConfirmation
        confirmInfo={confirmInfoSign}
        handleCloseDialog={handleCloseConfirmDialogSign}
      />
      <MySnackbar
        info={infoSnack}
        handleClose={() => setInfoSnack({ open: false })}
      />
      {flow?.document?.file
        && <>
          <FormTagsModal
            defaultValue={tagValues}
            open={openFormTagsModal}
            tags={flow.template.tags}
            onSubmit={(values) => {
              const keys = Object.keys(values);
              for (let i = 0; i < keys.length; i++) {
                editorRef.mailMergeOptions._native.dataSource._items[0][
                  keys[i]
                ] = values[keys[i]];
              }
              editorRef.mailMergeOptions.viewMergedData = false;
              editorRef.mailMergeOptions.viewMergedData = true;

              setTagValues({ ...tagValues, ...values });
            }}
            onClose={() => setOpenFormTagsModal(false)}
          />
          <DocumentPreviewModal
            open={openPreviewModal}
            flow={flow}
            onClose={() => setOpenPreviewModal(false)}
          />
          <MyBackdrop open={finishing} title={finishing} />
        </>
      }
    </Container>
  );
}
