import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import SchemaIcon from "@mui/icons-material/Schema";

import { cancelFlow, getFlowOnSnapshot, updateFlow } from "../../services/backend/FlowService";
import MySnackbar from "../generic/MySnackbar";
import ActionFlowItem from "./ActionFlowItem";
import ProcessHeader from "../processes/ProcessHeader";
import DialogConfirmation from "../generic/DialogConfirmation";
import { PERMISSIONS } from "../../constants";
import MyBackdrop from "../generic/MyBackdrop";
import AnnexesUploader from "../generic/AnnexesUploader";
import BindExpedient from "../expedient/BindExpedient";

export default function Flow({ flowIdNew, parentSetFlow, disabled }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const { flowId } = useParams();
  const [flow, setFlow] = useState(null);
  const [infoSnack, setInfoSnack] = useState({ open: false });
  const [confirmInfo, setConfirmInfo] = useState({ open: false });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [annexes, setAnnexes] = useState([]);
  const [attach, setAttach] = useState('');
  const [expedient, setExpedient] = useState(undefined);
  const [copyInExpedient, setCopyInExpedient] = useState(false);

  const handleOpenTask = async (taskId) => {
    if (taskId) {
      navigate(`/task/${taskId}`);
    }
  };

  const handleCancelProcess = async (result) => {
    setConfirmInfo({ open: false });
    setOpenBackdrop(true);

    if (result) {
      const response = await cancelFlow(flow, user);
      if (response.result) {
        setInfoSnack({
          open: true,
          message: 'El proceso ha sido cancelado con éxito'
        });
        navigate('/processes');
      } else {
        setInfoSnack({
          open: true,
          message: 'Ha ocurrido un error. Vuelve a intentarlo',
          severity: 'error'
        });
      }
    }

    setOpenBackdrop(false);
  };

  useEffect(() => {
    const newFlowId = flowId ? flowId : flowIdNew ? flowIdNew : null;
    if (newFlowId) {
      let unsubscribeTasks = getFlowOnSnapshot(newFlowId, (flow) => {
        setFlow(flow);
        if (parentSetFlow) parentSetFlow(flow);
      });
      return () => {
        unsubscribeTasks();
      };
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!flow) return;
    let canRead = user.roles.includes('ADMIN') ? true : false;
    flow.actions.forEach((action) => action.owners.forEach(
      (owner) => { if (owner.userId === user.userId) canRead = true; }
    ));
    if (!canRead) navigate('/processes');
    if (flow.annexes) setAnnexes(flow.annexes);
    if (flow.attach) setAttach(flow.attach);
    if (flow.expedient) {
      setExpedient(flow.expedient);
      setCopyInExpedient(flow.expedient.copyInExpedient)
    }
  }, [flow]); // eslint-disable-line

  useEffect(() => {
    if (flow) {
      updateFlow({
        flowId: flow.flowId,
        annexes,
        expedient: expedient ? { ...expedient, copyInExpedient } : null
      }, user);
    }
  }, [annexes, expedient, copyInExpedient]); // eslint-disable-line

  return (
    <Container maxWidth="md" style={{ padding: 3, marginTop: 10 }}>
      {flow ? (
        <>
          <ProcessHeader
            editTemplate={false}
            editSubject={false}
            process={flow}
            setProcess={setFlow}
          />
          <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <SchemaIcon size="large" color="primary" />
                  <Typography variant="h6" gutterBottom>
                    Flujo de Trabajo
                  </Typography>
                  {flow?.status === "inProgress" ? (
                    <Chip
                      label="En Progreso"
                      color="success"
                      variant="outlined"
                      onClick={() => navigate(`/flow/${flow.flowId}`)}
                    />
                  ) : flow?.status === "done" ? (
                    <Chip
                      label="Finalizado"
                      color="success"
                      onClick={() => navigate(`/flow/${flow.flowId}`)}
                    />
                  ) : (
                    <Chip
                      label="Cancelado"
                      color="error"
                      variant="outlined"
                      onClick={() => navigate(`/flow/${flow.flowId}`)}
                    />
                  )}
                  {flow.status === 'inProgress'
                    && (flow.userId === user.userId || user.permissions.includes(PERMISSIONS.PROCESS.CANCEL_FLOW))
                    && <Button
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={() => setConfirmInfo({
                        open: true,
                        title: "¿Seguro desea cancelar el proceso?"
                      })}
                    >
                      Cancelar Proceso
                    </Button>
                  }
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {flow?.actions?.map((action) =>
                action.owners?.map((owner) => (
                  <div key={owner.userId}>
                    <ActionFlowItem
                      flow={flow}
                      key={action.actionName}
                      flowItem={{ ...action, ...owner }}
                      handleOpenTask={handleOpenTask}
                    />
                  </div>
                ))
              )}
            </Grid>
            <Grid item xs={12}>
              <AnnexesUploader
                referenceCode={`FLOW_ANNEXES/${flow.flowId}`}
                annexes={annexes}
                setAnnexes={setAnnexes}
                attach={attach}
                setAttach={setAttach}
                disabled={disabled}
                onBlur={() => {
                  if (flow) {
                    updateFlow({
                      flowId: flow.flowId,
                      attach
                    }, user);
                  }
                }}
              />
              <BindExpedient
                flow={flow}
                expedient={expedient}
                setExpedient={setExpedient}
                copyInExpedient={copyInExpedient}
                setCopyInExpedient={setCopyInExpedient}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <CircularProgress />
      )}
      <MySnackbar
        info={infoSnack}
        handleClose={() => setInfoSnack({ open: false })}
      />
      <DialogConfirmation
        confirmInfo={confirmInfo}
        handleCloseDialog={handleCancelProcess}
      />
      <MyBackdrop open={openBackdrop} />
    </Container>
  );
}
